import ScaledView from "@/public_obituary/book/flip-book/ScaledView";
import React, {useCallback, useEffect, useState} from "react";

export default function TableOfContents({pageFlip, html}) {
    const [ref, setRef] = useState(null);
    const onRef = useCallback((ref) => {
        setRef(ref);
    }, []);

    useEffect(() => {
        if (!ref) return;
        const fn = (button) => {
            return () => flip(button);
        };
        const flip = (button) => {
            const page = parseInt(button?.dataset.pageNumber);
            pageFlip?.flip(page);
        };

        const buttons = ref.querySelectorAll('button.toc-section');
        const buttonFns = [...buttons].map((button) => {
            const buttonFn = fn(button);
            button.addEventListener('click', buttonFn);
            return [button, buttonFn];
        });

        return () => {
            buttonFns.forEach(([button, buttonFn]) => {
                button.removeEventListener('click', buttonFn);
            });
        };
    }, [ref]);

    return (
        <ScaledView
            show={true}
            originY="top"
            originX="left"
            width="8.5in"
            className="z-10"
            style={{height: '11in', mixBlendMode: 'multiply'}}
        >
            <div ref={onRef} className={"absolute w-full h-full"} dangerouslySetInnerHTML={{__html: html}}/>
        </ScaledView>
    );
}
