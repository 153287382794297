import ScaledView from "@/public_obituary/book/flip-book/ScaledView";
import React from "react";

export default function GuestbookCTA({ obituary, show }) {
    const scrollToElement = (elementId) => {
        const targetElement = document.getElementById(elementId);
        if (targetElement) {
            targetElement.scrollIntoView({behavior: 'smooth', block: 'start'});
        }
    };

    // check if current url matches public url
    const url = window.location.href;
    const isEmbed = !url?.startsWith(obituary?.public_url);

    return (
        <ScaledView
            show={show}
            originY="bottom"
            originX="center"
            width="4.5in"
            style={{bottom: "11%"}}
        >
            <div
                className="mx-16 bg-blue-50 text-center border p-10 border-blue-200 rounded-lg shadow-lg shadow-blue-100 text-black">
                <div className="font-bold mb-2">
                    Share Your Memory
                </div>

                <p className="text-sm mb-4">Contribute your memory to the online guestbook to be
                    featured in this unique keepsake book.</p>

                <a
                    href={`${obituary?.public_url}#tukios-obituary-guestbook`}
                    target="_blank"
                    onClick={(e) => {
                        if (isEmbed) return;
                        e.preventDefault();
                        window.dispatchEvent(new CustomEvent('modal-close'));
                        setTimeout(() => {
                            scrollToElement('tukios-obituary-guestbook');
                        }, 500);
                    }}
                    className="toggle-modal inline-block md:w-auto shadow-sm border border-transparent text-white text-sm bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 px-4 py-2 rounded-md inline-flex items-center justify-center font-medium focus:outline-none focus:ring-2 focus:ring-offset-2"
                >
                    Add to Guestbook
                </a>
            </div>
        </ScaledView>
    );
}
