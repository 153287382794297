import {useEffect, useState} from "react";

export default function usePageFlip(events) {
    const [pageFlip, setPageFlip] = useState(null);
    useEffect(() => {
        if (!events) return;
        const fn = (e) => setPageFlip(e.detail);
        events.addEventListener('init', fn);
        return () => events.removeEventListener('init', fn);
    }, [events]);

    return pageFlip;
}
