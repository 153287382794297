import {forwardRef, memo, useCallback, useEffect, useState} from "react";

const Iframe = memo(forwardRef(
    function ({content, onLoad, scale}, ref) {
        const [iframeEl, setIframeEl] = useState(null);
        const [isLoading, setIsLoading] = useState(true);

        const handleIframeLoad = useCallback(() => {
            const iframe = iframeEl;
            if (!iframe || content === '') return;

            const doc = iframe.contentDocument || iframe.contentWindow.document;
            onDocumentImagesLoaded(doc).then(() => {
                setIsLoading(false);
                if (typeof onLoad === 'function') {
                    onLoad();
                }
            });
        }, [iframeEl, content]);

        const onIframeRef = useCallback((el) => {
            if (ref) ref(el);
            setIframeEl(el);
        }, [ref]);

        return (
            <iframe
                className="pointer-events-none"
                ref={onIframeRef}
                srcDoc={content}
                style={{
                    background: '#fff',
                    width: '8.5in',
                    height: '11in',
                    border: 'none',
                    transformOrigin: 'top left',
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    transform: scale ? `scale(${scale.x}, ${scale.y})` : 'none',
                    visibility: !isLoading ? 'visible' : 'hidden',
                }}
                onLoad={handleIframeLoad}
            ></iframe>
        )
    }
));

const imagesLoading = {};

async function onDocumentImagesLoaded(doc) {
    const allElements = Array.from(doc.querySelectorAll('*'));
    // Check for img elements and background images
    return await Promise.all(allElements.map(async el => {
        /**
         * PROCESS <IMG> ELEMENTS
         */
        if (el.tagName === 'IMG' && el.src) {
            return waitForImage(el);
        }

        /**
         * PROCESS BACKGROUND IMAGES
         */
        const style = window.getComputedStyle(el);
        const backgroundImage = style.backgroundImage;
        if (!backgroundImage || backgroundImage === 'none') return;

        const match = /url\("?(.*?)"?\)/.exec(backgroundImage);
        if (!match || !match[1]) return;
        const src = match[1];

        if (imagesLoading[src]) return imagesLoading[src].promise;

        const bgImg = new Image();
        bgImg.src = src;
        const promise = waitForImage(bgImg);
        imagesLoading[src] = {img: bgImg, promise};
        return promise;
    }));
}

function waitForImage(image) {
    if (image.complete && image.naturalHeight !== 0) {
        return Promise.resolve();
    }
    return new Promise((resolve) => {
        image.onload = resolve;
        image.onerror = resolve;
    });
}

export default Iframe;
