import {useEffect} from "react";

export default function useElementResize(el, fn, deps, debounceMs = 0) {
    useEffect(() => {
        if (!el) return;
        let timeout;
        const handler = () => {
            clearTimeout(timeout);
            timeout = setTimeout(fn, debounceMs);
        }

        const observer = new ResizeObserver(() => {
            handler();
        });
        observer.observe(el);
        fn();

        return () => {
            clearTimeout(timeout);
            observer.disconnect();
        };
    }, [el, ...deps]);
}
