import {useCallback, useState} from "react";
import useElementResize from "@/public_obituary/book/flip-book/hooks/useElementResize";
import {classNames} from "@/components/common/Helpers";

export default function ScaledView(
    {children, width, show, originY = 'top', originX = 'left', className, style}
) {
    const [overlayRef, setOverlayRef] = useState(null);
    const [overlayScale, setOverlayScale] = useState(1);

    const onOverlayRef = useCallback((ref) => {
        setOverlayRef(ref);
    }, []);

    const scaleOverlay = useCallback(() => {
        if (!overlayRef) return;
        const container = overlayRef.parentNode;
        const scale = container.clientWidth / overlayRef.clientWidth;
        setOverlayScale(Math.min(1, scale));
    }, [overlayRef]);

    useElementResize(overlayRef?.parentNode, scaleOverlay, []);

    let top = 0;
    let bottom = undefined;
    let left = 0;
    let right = undefined;
    let transform = `scale(${overlayScale})`;

    if (originY === 'center') {
        top = '50%';
        transform += ' translateY(-50%)';
    } else if (originY === 'bottom') {
        top = undefined;
        bottom = 0;
    }

    if (originX === 'center') {
        left = '50%';
        transform += ' translateX(-50%)';
    } else if (originX === 'right') {
        left = undefined;
        right = 0;
    }

    return (
        <div
            ref={onOverlayRef}
            className={classNames("absolute", className)}
            style={{
                width,
                visibility: show ? 'visible' : 'hidden',
                transformOrigin: `${originY} left`,
                top,
                bottom,
                left,
                right,
                transform,
                ...style,
            }}
        >
            {children}
        </div>
    );
}
