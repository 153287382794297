import { configureStore } from '@reduxjs/toolkit'
import { createManagedResource } from '@/vendor/react-relaxful/relax/resourceCreator'

const obituaryId = document.getElementById('tukios-obituary-app')?.getAttribute('data-obituary-id')

const api_url = `${import.meta.env.VITE_API_URL}/public`
const api_sub_url = `${api_url}/obituaries/${obituaryId}`

/**
 * Registry of all managed resource stores
 */

export const obituaryEntity = createManagedResource('obituary', 'obituaries', api_url)
export const guestbookEntity = createManagedResource('guestbook', 'guestbook', api_sub_url)
export const obituaryCandleEntity = createManagedResource('obituaryCandle', 'candles', api_sub_url)
export const obituaryCampaignEntity = createManagedResource('obituaryCampaign', 'obituary-campaigns', api_sub_url)
export const obituaryCampaignDonationEntity = createManagedResource('obituaryCampaignDonation', 'obituary-campaigns/{campaign}/donations', api_sub_url)
export const obituaryCampaignDonationIntentEntity = createManagedResource('obituaryCampaignDonationIntent', 'obituary-campaigns/{campaign}/intent', api_sub_url)
/**
 * Root Redux Store
 */
export const store = configureStore({
    reducer: {
        obituaryReducer: obituaryEntity.reducer,
        guestbookReducer: guestbookEntity.reducer,
        obituaryCampaignReducer: obituaryCampaignEntity.reducer,
        obituaryCampaignDonationReducer: obituaryCampaignDonationEntity.reducer,
        obituaryCampaignDonationIntentReducer: obituaryCampaignDonationIntentEntity.reducer,
        obituaryCandleReducer: obituaryCandleEntity.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    }),
    devTools: import.meta.env.VITE_APP_ENV !== 'production',
})
